// userSettings.js

import { reactive } from "vue"
import { getRouteQueryParams } from "@/utils/settings"
import services from "@/services"

class UserData {
  constructor() {
    this.messageSound = true
    this.userTheme = this.detectTheme()
    this.language =
      navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0]
    // this.language = "en";
    this.session_id = ""
    this.cup_id = getRouteQueryParams(window.location.pathname)
    this.query = ""
    this.token = ""
    this.isRegistered = false
    this.cuppScanCount = 0
  }

  async updateAsyncData() {
    const result = await services.getSession({
      cupId: getRouteQueryParams(window.location.pathname),
    })
    this.session_id = result.data.session_id
    this.query = result.data.query
    this.token = result.data.token
    this.isRegistered = result.data.is_registered
  }

  detectTheme() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return "dark"
    }
    return "light"
  }
}

export const userSettings = reactive(new UserData())
